import React from 'react';
import Statement from '../components/Statement';
import Content from '../components/Content';

function AboutPage(props) {
    return (
        <div>
            <Statement title={props.title} />

            <Content>
            <p> Hello, my name is Kalista! I am a recent Ryerson University graduate, obtaining my bachelor of science degree in Computer Science.
                I have experience coding in Java, C, C++, C#, JavaScript, Python, CSS, HTML, React, Angular, and NodeJs. I have gained my skills through working on school projects as well as developing my own personal
                projects.
            </p>
            <p> There is so much to learn in the computer scinece world and I am eager to learn as much of it as I can. As a result, I am constantly learning new things, looking for youtube videos to teach me how to code in a new language or how to use software I am unfamiliar with. 
                Currently I am learning how to program in React! The purpose of this website is to not only have one organized place where people can see the projects I'm working on, but to also begin programmig in React.</p>

            <p>I love programming because it allows me to be as creative as possible and opens up many opprotunities to experience working in a vartiety of diffrent industries. </p>
            
            <p> Projects that I have worked on and am still addding to is GoGreen and Money Watch. GoGreen is a site that was created out of my growing interest in environemntal sustainability. It provides infomration to those who are interted in being more environmentaly sustainable but don't know where to start.
                MoneyWatch was designed for me to have a visual representation of how my money is being spent. </p>
        </Content>
    </div>
    )
}

export default AboutPage;
