import React from 'react';
import CardInfo from '../components/CardInfo';

function Card(props) {

    return(
        <div className="d-inline-block my-card" onClick={(e) => props.click(props.item)}>
            <a href={props.item.link}><img className="my-card-image" src={props.item.imgSrc} alt={props.item.imgSrc} /></a>
            {/* conditionally rendered componet - card info because dont want to show it unless it is selected */}
            { <CardInfo title={props.item.title} subtitle={props.item.subtitle} link={props.item.link} gitLink={props.item.gitLink} /> }
        </div>
    );

}

export default Card;