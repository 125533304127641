import React from 'react';
import Statement from '../components/Statement';
import Carousel from '../components/Carousel';
import Content from '../components/Content';

function Homepage(props) {
    return (

        <Content>
             <div>
            <Statement title={props.title} subtitle={props.subtitle} prompt={props.prompt} />
            <Carousel />
        </div>
        </Content>
       
    )
}

export default Homepage;