import React  from 'react';
import Card from '../components/Card'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import gogreen from '../assets/images/gogreen.png';
import taskapp from '../assets/images/taskapp.png';
import todo from '../assets/images/todolist.png';
import kbingo from '../assets/images/kbingo.png';
import babysTime from '../assets/images/babysTime.png';

class Carousel extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            items: [
                {
                    id: 0,
                    title: 'TaskApp',
                    subtitle: 'The Task App is a task scheduling app to be used by truck dispatchers to organize the schedules of dispatched truck drivers.',
                    imgSrc: taskapp,
                    link: 'http://taskapp.kalistabaig.com',
                    gitLink: 'https://github.com/kalistabaig/taskSchedulingApp',
                    selected: false
                },
                {
                    id: 1,
                    title: 'GoGreen',
                    subtitle: 'Developed a website to improve my full stack programming skills. The website provides a place where people can easily find information on how they can make small changes in their daily routine or products they can purchase to be more environmentally sustainable.',
                    imgSrc: gogreen,
                    link: 'http://gogreen.kalistabaig.com',
                    gitLink: 'https://github.com/kalistabaig/GoGreen',
                    selected: false
                },
                {
                    id: 2,
                    title: 'ToDo',
                    subtitle: 'Developed a single page web application as a way to test my knowledge of full stack web development and using REST APIs. This is a simple To Do List application that allows the user to add tasks they need comleted and assign vary degrees of importance to.',
                    imgSrc: todo,
                    link: 'http://todo.kalistabaig.com',
                    gitLink: 'https://github.com/kalistabaig/ToDoList',
                    selected: false
                },
                {
                    id: 3,
                    title: 'KBingo',
                    subtitle: 'Developed a single page web application to play Bingo with a KDrama theme.',
                    imgSrc: kbingo,
                    link: 'http://kbingo.kalistabaig.com',
                    gitLink: 'https://github.com/kalistabaig/KBingo',
                    selected: false
                }
               
            ],
            iosItems: [
                {
                    id: 0,
                    title: 'Baby\'s Time',
                    subtitle: 'An IOS app designed to track a baby\'s activities throughout the day for busy parents.',
                    imgSrc: babysTime,
                    gitLink: 'https://github.com/kalistabaig/BabysTime',
                    selected: false
                }

            ]

        }

    }

    handleCardClick = (id, card) => {

        let items = [...this.state.items];
        items[id].selected = items[id].selected ? false : true;
        items.forEach(item => {
            if (item.id !== id) {
                item.selected = false;
            }
        });

        this.setState({ // we have to set the state so that all the work we just did gets done
            items
        });
    }

    makeItems = (items) => { //function creates a card componet for each of our items; doing this way you dont have to bing but why
        return items.map(item => {
            return <Card item={item} click={(e => this.handleCardClick(item.id, e))} key={item.id} />
        })
    }

    render() {
        return (
            <Container fluid={true}>
                <h1>Projects</h1>
                <h3>Web Apps</h3>
                <Row>
                    {this.makeItems(this.state.items)}
                </Row>
                <h3>IOS Apps</h3>
                <Row>
                    {this.makeItems(this.state.iosItems)}
                </Row>
        </Container>
        );
    }

}

export default Carousel;