import React from 'react';
import Content from '../components/Content';
import Statement from '../components/Statement';

function ContactPage(props) {
    return (
        <div>
            <Statement title={props.title} subtitle="Email: kalistaSbaig@gmail.com" />
        </div>
        
    )
}

export default ContactPage;