import React from 'react';
import { useSpring, animated } from 'react-spring';
import gitIcon from '../assets/images/gitIcon.png';

function CardInfo(props) {

    const style = useSpring({opacity: 1, from: {opacity: 0}}); // var holds the react spring style we are going to be using

    return(
        <animated.div className="my-card-info" style={style}>
            <p className="my-card-title">{props.title}</p>
            <p className="my-card-sub-title">{props.subtitle}</p>
            <a href={props.link} target="_blank" rel="noopener noreferrer">View</a>
            <a href={props.gitLink} className="git-button"><img className="git-image" src={gitIcon}></img></a>
        </animated.div>
    );

}

export default CardInfo;