import React from 'react';
import {BrowserRouter as Router, Route, Link} from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import { Navbar } from 'react-bootstrap';
import { Nav } from 'react-bootstrap';
import './App.css';


import Homepage from './pages/Homepage';
import AboutPage from './pages/AboutPage';
import ContactPage from './pages/ContactPage';

class App extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      title: 'Kalista Baig',
      headerLinks: [
        {title: 'Home', path: '/'},
        {title: 'About', path: '/about'},
        {title: 'Resume', path: '/resume'},
        {title: 'Contact', path: '/contact'}
      ],
      home: {
        title: 'Kalista Baig',
        subtitle: 'Up and Coming Software Developer',
        prompt: 'Get to Know Me, browse my site!'
      },
      about: {
        title: 'About Me'
      },
      resume: {
        title: 'Resume'
      },
      contact: {
        title: "Let's Chat"
      }
    }
  }
  
  render() {
    return (
      <Router>
        <Container className='p-0' fluid="true">
          <Navbar className='border-bottom' bg="transparent" expand="lg">
          <Navbar.Brand>Kalista Baig</Navbar.Brand>
            <Navbar.Toggle className="border-0" aria-controls='navbar-toggle'></Navbar.Toggle>
            <Navbar.Collapse id="navbar-toggle">
              <Nav className="ml-auto">
                <Link className="nav-link" to="/">Home</Link>
                <Link className="nav-link" to="/about">About Me</Link>
                <a className="nav-link" href="/KalistaBaigResume.pdf">Resume</a>
                <Link className="nav-link" to="/contact">Contact</Link>
              </Nav>
            </Navbar.Collapse>
          </Navbar>

          <Route path="/" exact render={() => <Homepage title={this.state.home.title} subtitle={this.state.home.subtitle} prompt={this.state.home.prompt} />} />
          <Route path="/about" render={() => <AboutPage title={this.state.about.title} />} />
          <Route path="/contact" render={() => <ContactPage title={this.state.contact.title} />} />
          
          {/* <Route path='/moneywatch' component={() => { 
              window.location.href = 'http://kalistabaig.com:5500'; 
              return null;
          }}/>
          <Route path='/gogreen' component={() => { 
              window.location.href = 'http://kalistabaig.com:3000'; 
              return null;
          }}/>
          <Route path='/todo' component={() => { 
              window.location.href = 'http://kalistabaig.com:5000'; 
              return null;
          }}/>
           <Route path='/taskapp' component={() => { 
              window.location.href = 'http://kalistabaig.com:3500'; 
              return null;
          }}/> */}

        </Container>
      </Router>
    );
  }
}
export default App;
